export const ANSWER_TYPE_OTHER = 'other'
export const LABEL_OTHER = 'pro.onboarding_form.other'
export const NO_SCORE = 'no_score'
export const CONFIGURATION_PACKAGE_FORM = 'CONFIGURATION_PACKAGE_FORM'

export const STORAGE_PACKAGES_CATEGORY = 'Rangement et dressing'
export const STORAGE_PACKAGES_NAME = 'storage_dressing'
export const CARPENTRY_PACKAGES_CATEGORY = 'Menuiserie'
export const CARPENTRY_PACKAGES_NAME = 'carpentry'
export const TERRACE_PACKAGES_CATEGORY = 'Terrasse et jardin'
export const TERRACE_PACKAGES_NAME = 'terrace'
export const BATHROOM_PACKAGES_CATEGORY = 'Salle de bains'
export const BATHROOM_PACKAGES_NAME = 'bathroom'
export const KITCHEN_PACKAGES_CATEGORY = 'Cuisine'
export const KITCHEN_PACKAGES_NAME = 'kitchen'
export const HEATING_PACKAGES_CATEGORY = 'Chauffage et plomberie'
export const HEATING_PACKAGES_NAME = 'heating'
export const ELECTRICITY_PACKAGES_CATEGORY = 'Électricité et domotique'
export const ELECTRICITY_PACKAGES_NAME = 'electricity'
export const HARDWARE_STORE_PACKAGES_CATEGORY = 'Quincaillerie'
export const HARDWARE_STORE_PACKAGES_NAME = 'hardware_store'
export const GROUND_PACKAGES_CATEGORY = 'Mur et sol'
export const GROUND_PACKAGES_NAME = 'ground'
export const OTHERS_PACKAGES_CATEGORY = 'Autre'
export const OTHERS_PACKAGES_NAME = 'other'
export const ZONE_NAME = 'postcodes'
export const VAT_NAME = 'vatNumber'
export const SWIFT_NAME = 'swiftCode'
export const IBAN_NAME = 'internationalBankAccountNumber'
export const MATCHING_OPTION_DEPARTMENT = 'MATCHING_OPTION_DEPARTMENT'
export const PRODUCT_TYPES = {
  package: 'service_package',
}
export const VAT_CATEGORY = 'subjectToVat'
export const REGISTRATION_NUMBER = 'registrationNumber'

export const MENU_PACKAGE_LIST = {
  storage_dressing: {
    name: 'storage_dressing',
    icon: 'furnishings',
    to: '#storage_dressing',
    type: STORAGE_PACKAGES_NAME,
    active: 'storage_dressing',
  },
  carpentry: {
    name: 'carpentry',
    icon: 'hammer',
    to: '#carpentry',
    type: CARPENTRY_PACKAGES_NAME,
    active: 'carpentry',
  },
  terrace: {
    name: 'terrace',
    icon: 'garden',
    to: '#terrace',
    type: TERRACE_PACKAGES_NAME,
    active: 'terrace',
  },
  bathroom: {
    name: 'bathroom',
    icon: 'bathroom',
    to: '#bathroom',
    type: BATHROOM_PACKAGES_NAME,
    active: 'bathroom',
  },
  kitchen: {
    name: 'kitchen',
    icon: 'kitchen',
    to: '#kitchen',
    type: KITCHEN_PACKAGES_NAME,
    active: 'kitchen',
  },
  heating: {
    name: 'heating',
    icon: 'plumbing',
    to: '#heating',
    type: HEATING_PACKAGES_NAME,
    active: 'heating',
  },
  electricity: {
    name: 'electricity',
    icon: 'electricity',
    to: '#electricity',
    type: ELECTRICITY_PACKAGES_NAME,
    active: 'electricity',
  },
  ground: {
    name: 'ground',
    icon: 'flooring',
    to: '#ground',
    type: GROUND_PACKAGES_NAME,
    active: 'ground',
  },
}
