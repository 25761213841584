import accents from 'remove-accents'
import isEmail from 'validator/lib/isEmail'
import isInt from 'validator/lib/isInt'
import isCreditCard from 'validator/lib/isCreditCard'
import isPostalCode from 'validator/lib/isPostalCode'
import { validDateFormat, isAuthorizedDate } from 'services/validation/date'
import { DATE_FORMAT } from 'constants/dateFormat'
import { isSIRET } from 'utils/pro/verifySiret'
import { getConfigSizeImg } from 'constants/pictures'
import { isValid } from 'iban'
import { checkVAT, france } from 'jsvat'
import { BOARDING_FORM_SUGGESTION_MAX_LENGTH } from 'constants/form'

export const isEmpty = value =>
  value === undefined || value === null || value === ''
const join = rules => (value, data) =>
  rules.map(rule => rule(value, data)).filter(error => !!error)[0]
const passwordPattern = /^(?=.*[a-z])(?=.*\d)(?=.*(_|[^\w\s])).{8,64}$/
const phoneNumberPatternLegacy = {
  'en-GB': /^(0|\+44)(([1238]{1}[0-9]{8,9})|(7[1-9]{1}[0-9]{7,8}))$/,
  'fr-FR': /^(0|\+33)[1-9][0-9]{8}$/,
  'es-ES': /^(\+34)?[5-9][0-9]{8}$/,
}
const mobilePhoneNumberPattern = {
  'en-GB': /^.+$/,
  'fr-FR': /^(0|\+33)[6|7][0-9]{8}$/,
  'es-ES': /^(0|\+34)[6|7][0-9]{8}$/,
}
const validateFormatFn = validDateFormat(
  DATE_FORMAT.LONG_FORMAT_REGEXP,
  DATE_FORMAT.FR_LONG,
)

// Redux Form validators
export const siret = locale => value =>
  !isEmpty(value) &&
  !isSIRET(value, locale) && { id: 'validators.pro.invalid_siret', values: {} }
const isNumber = value => !isEmpty(value) && /[0-9]/.test(value)
export const name = value =>
  isNumber(value) && { id: 'validators.user.invalid_name', values: {} }
export const hasNoNumber = value =>
  isNumber(value) && {
    id: 'package_candidate.contract_edition.invalid_format',
    values: {},
  }
export const email = value =>
  (!isEmpty(value) && !isEmail(value)) ||
  (!isEmpty(value) && isEmail(value) && accents.has(value))
    ? { id: 'validators.user.invalid_email', values: {} }
    : null
export const required = value =>
  isEmpty(value) && { id: 'validators.required_field', values: {} }
export const isChecked = value =>
  (isEmpty(value) || !value) && {
    id: 'validators.must_be_checked',
    values: {},
  }
export const isDate = value => validateFormatFn(value)
export const notAfterThreeMonth = (value, formated) =>
  !isAuthorizedDate(value, 3, formated) && {
    id: 'validators.date.not_after_three_months',
    values: {},
  }
export const checked = value =>
  value !== true && { id: 'validators.required_field', values: {} }
export const integer = value =>
  !isEmpty(value) && !isInt(value) && { id: 'validators.integer', values: {} }
export const hasOnlyNumber = value =>
  !isEmpty(value) &&
  !isInt(value) && {
    id: 'package_candidate.contract_edition.integer',
    values: {},
  }
export const maxInt = value =>
  value > 2147483647 && { id: 'validators.maxInt', values: {} }

export const match = field => (value, data) =>
  data && value !== data[field] && { id: 'validators.must_match', values: {} }
export const password = value =>
  !isEmpty(value) &&
  !passwordPattern.test(value) && {
    id: 'validators.user.password.invalid_password',
    values: {},
  }
export const postalCode = locale => value =>
  !isEmpty(value) &&
  !(isPostalCode(value, locale) && (locale !== 'GB' || value.length > 5)) && {
    id: 'validators.postalCode.must_match_required',
    values: {},
  }
export const phone = locale => value =>
  !isEmpty(value) &&
  !phoneNumberPatternLegacy[locale].test(value) && {
    id: 'validators.user.phone.invalid',
    values: {},
  }
export const mobilePhone = locale => value =>
  !isEmpty(value) &&
  !mobilePhoneNumberPattern[locale].test(value) && {
    id: 'validators.user.mobilePhone.invalid',
    values: {},
  }
export const longerThan = minSize => value =>
  !isEmpty(value) &&
  value.length < minSize && {
    id: 'validators.text.too_short',
    values: { length: minSize },
  }
export const maxLength = maxSize => value =>
  !isEmpty(value) &&
  value.length > maxSize && {
    id: 'validators.text.too_long',
    values: { length: maxSize },
  }
export const length = size => value =>
  !isEmpty(value) &&
  value.length !== size && {
    id: 'validators.text.length',
    values: { length: size },
  }
export const creditCard = value =>
  !isEmpty(value) &&
  !isCreditCard(value) && {
    id: 'validators.payment.credit_card.invalid',
    values: {},
  }
export const creditCardCvx = value =>
  !isEmpty(value) &&
  !/^[0-9]{3,4}$/.test(value) && {
    id: 'validators.payment.credit_card_cvx.invalid',
    values: {},
  }
export const numberGreaterThan = minValue => value =>
  !isEmpty(value) &&
  value <= minValue && {
    id: 'validators.number.greater_than',
    values: { minValue },
  }

// Validation functions

export { default as date, validShortDate, DATE_SHORT_FORMAT } from './date'

export const createValidator =
  rules =>
  (data = {}) => {
    const errors = {}
    Object.keys(rules).forEach(key => {
      const rule = join([].concat(rules[key]))
      const error = rule(data[key], data)

      if (error) {
        errors[key] = error
      }
    })
    return errors
  }

export const isImgValidByConfig = (img, file, fieldname) => {
  const configSize = getConfigSizeImg(fieldname)

  return (
    (file.size / 1024 / 1024).toFixed(4) < 5 &&
    img.width >= configSize.minWidth &&
    img.height >= configSize.minHeight &&
    img.width / img.height < 4 &&
    img.height / img.width < 4
  )
}

export const isValidIban =
  (countryCode = 'FR') =>
  value =>
    !isEmpty(value) &&
    (value.slice(0, 2) !== countryCode ||
      (value.slice(0, 2) === countryCode && !isValid(value))) && {
      id: 'validators.user.password.invalid_iban',
      values: {},
    }

export const isValidVAT =
  (countryCode = 'FR') =>
  value =>
    !isEmpty(value) &&
    (value.slice(0, 2) !== countryCode ||
      (value.slice(0, 2) === countryCode &&
        !checkVAT(value, [france]).isValid)) && {
      id: 'validators.user.password.invalid_vat',
      values: {},
    }

export const isValidSWIFT = () => value =>
  !isEmpty(value) &&
  !/^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/.test(
    value.toUpperCase(),
  ) && {
    id: 'validators.user.password.invalid_bic',
    values: {},
  }

export const atLeastOneSelectFilled = fields => (value, allValues) => {
  for (let i = 0; i < fields.length; i += 1) {
    if (!isEmpty(allValues[fields[i]])) {
      return null
    }
  }

  return {
    id: 'validators.text.fill_at_least_one_select',
    values: {},
  }
}

export const suggestionMaxLength = value =>
  value && value.length > BOARDING_FORM_SUGGESTION_MAX_LENGTH
    ? { id: 'validators.suggestion.max_length' }
    : undefined

export const hasSpecialCharacters = value => {
  const format =
    /[`!@#$%^&*()_+=[\]{};':"\\|,.<>/?~§£€°•„“”‘’¶«»¡Ø–ø—¨¥‰≠±÷…∞¿®†ºπ‡∂ƒﬁ¬µ‹›≈©◊ßı∫√¢⁄ﬂ·∆∑ΩÅ‚™ª∏]/

  if (format.test(value)) {
    return {
      id: 'validators.special_characters',
      values: {},
    }
  }
}
