import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import configureStore from 'store/index'

import App from './components/App/App'

const store = configureStore()

const initializeApplication = async () => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <App store={store} />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root'),
  )
}

initializeApplication()

export default store
