/* eslint-disable no-use-before-define */
import axios from 'axios'
import qs from 'qs'
import logging from 'logging'
import { api } from 'config'
import { fromContext, fromAuth } from 'store/selectors'
import { setAppOperational, setLoggedIn } from 'store/auth/actions'

export default async store => {
  const state = store.getState()
  const lang = fromContext.getLocale(state)
  setDefaultHeaders(lang)

  return axios.interceptors.response.use(null, async error => {
    const { response } = error

    if (error.response && response.status === 401) {
      await handleAnonymousAuth(store)
      const appOperational = fromAuth.isAppOperational(store.getState())
      if (appOperational && !response.config.retrying) {
        await axios.request({ ...response.config, retrying: true })
        return response
      }
    }

    logging.captureException(error)
    throw error
  })
}

const setDefaultHeaders = lang => {
  axios.defaults.baseURL = api.url
  axios.defaults.paramsSerializer = qs.stringify
  axios.defaults.headers.common = {
    ...axios.defaults.headers.common,
    Accept: 'application/ld+json',
    'Content-Type': 'application/json',
    'Accept-Language': lang,
  }
}

const handleAnonymousAuth = async store => {
  setLoggedInLazy(store)(false)
  setAppOperationalLazy(store)(true)
}

const setLoggedInLazy = store => value => {
  const loggedIn = fromAuth.isLoggedIn(store.getState())
  if (loggedIn !== value) {
    store.dispatch(setLoggedIn(value))
  }
}
const setAppOperationalLazy = store => value => {
  const appOperational = fromAuth.isAppOperational(store.getState())
  if (appOperational !== value) {
    store.dispatch(setAppOperational(value))
  }
}
