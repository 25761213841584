import { sentry } from 'config'
import isServerSide from 'utils/isServerSide'

export const EmptyRavenStub = {
  captureException: () => {},
  captureMessage: () => {},
}

let cachedRavenJS = null

const RavenJS = () => {
  if (cachedRavenJS === null) {
    cachedRavenJS = require('raven-js')

    cachedRavenJS
      .config(sentry.url, {
        environment: process.env.REACT_APP_TARGET,
        release: `v${process.env.REACT_APP_VERSION}`,
        tags: {
          git_commit: process.env.REACT_APP_GIT_SHA1,
        },
      })
      .install()

    if (!isServerSide()) {
      window.addEventListener('unhandledrejection', rejection => {
        console.error(rejection.reason)
        cachedRavenJS.captureException(rejection.reason)
      })
    }
  }

  return cachedRavenJS
}

const requireRaven = sentry.enabled ? RavenJS() : EmptyRavenStub

export default requireRaven
