import React from 'react'
import PropTypes from 'prop-types'
import GoogleTagManager from '../GoogleTagManager/GoogleTagManagerContainer'
import AxeptioManagerView from './AxeptioManagerView'

const AxeptioManager = ({
  setCookiesConsent = undefined,
  isFeatureFlagCookieEnabled = undefined,
  cookies,
  useTracking = undefined,
}) => {
  if (isFeatureFlagCookieEnabled && useTracking !== 'false') {
    return (
      <AxeptioManagerView
        setCookiesConsent={setCookiesConsent}
        cookies={cookies}
      />
    )
  }

  return <GoogleTagManager />
}

AxeptioManager.propTypes = {
  setCookiesConsent: PropTypes.func,
  cookies: PropTypes.object,
  isFeatureFlagCookieEnabled: PropTypes.bool,
  useTracking: PropTypes.bool,
}

export default AxeptioManager
