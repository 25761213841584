import { connect } from 'react-redux'
import { compose } from 'redux'
import { setCookiesConsentContext } from 'store/actions'
import { fromContext } from 'store/selectors'
import { isFeatureFlagEnabled as IsFeatureFlagOn } from 'feature-flag-consumer-js-lib'
import { CONSUMER_PRO_DESKTOP, COOKIE_CONSENT } from 'constants/features'
import { QUERY_TRACKING } from 'constants/routes'
import AxeptioManager from './AxeptioManager'

const mapStateToProps = state => ({
  cookies: fromContext.getCookies(state),
  useTracking: localStorage.getItem(QUERY_TRACKING),
  isFeatureFlagCookieEnabled: IsFeatureFlagOn(
    state,
    CONSUMER_PRO_DESKTOP,
    COOKIE_CONSENT,
  ),
})

const mapDispatchToProps = dispatch => ({
  setCookiesConsent: () => dispatch(setCookiesConsentContext()),
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AxeptioManager,
)
