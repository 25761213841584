import { put, select } from 'redux-saga/effects'
import { isFeatureFlagEnabled as IsFeatureFlagOn } from 'feature-flag-consumer-js-lib'
import { CONSUMER_PRO_DESKTOP, USE_REFRESH_TOKEN } from 'constants/features'

import { fromAuth } from 'store/selectors'
import { getRefreshTokenReq } from 'store/auth/actions'

export default function* handleRequest({
  requestActions,
  promise,
  actionParams,
  checkTokens = true,
}) {
  const isUseRefreshTokenFFEnabled = yield select(
    IsFeatureFlagOn,
    CONSUMER_PRO_DESKTOP,
    USE_REFRESH_TOKEN,
  )

  try {
    if (isUseRefreshTokenFFEnabled && checkTokens) {
      const idTokenExpAt = yield select(fromAuth.selectIdTokenExpAt)
      const now = new Date().getTime() / 1000

      if (idTokenExpAt !== null && now >= idTokenExpAt) {
        yield put(getRefreshTokenReq.request())
        throw new Error('Token expired before call api begin')
      }
    }

    const response = yield promise
    yield put(requestActions.success(response.data, actionParams))

    if (isUseRefreshTokenFFEnabled && checkTokens) {
      const refreshTokensAt = yield select(fromAuth.selectRefreshTokensAt)
      const now = new Date().getTime() / 1000

      if (refreshTokensAt !== null && now >= refreshTokensAt) {
        yield put(getRefreshTokenReq.request())
      }
    }

    return response
  } catch (e) {
    if (
      isUseRefreshTokenFFEnabled &&
      checkTokens &&
      e?.response?.data?.detail === 'Expired token'
    ) {
      yield put(getRefreshTokenReq.request())
    }

    yield put(requestActions.failure(e, actionParams))

    throw e
  }
}
