export const EMPLOYEES_NB_CHOICES = {
  FR: {
    ALONE: 'firm.details.employees_number.alone',
    LES_5: 'firm.details.employees_number.les_5',
    BET_6_15: 'firm.details.employees_number.bet_5_15',
    BET_16_50: 'firm.details.employees_number.bet_16_50',
    MOR_50: 'firm.details.employees_number.mor_50',
  },
  ES: {
    ALONE: 'firm.details.employees_number.alone',
    LES_5: 'firm.details.employees_number.les_5',
    BET_6_15: 'firm.details.employees_number.bet_5_15',
    BET_16_50: 'firm.details.employees_number.bet_16_50',
    MOR_50: 'firm.details.employees_number.mor_50',
  },
}

export const POP_IN_FIRM_COVER_EDIT_DISPLAY_FLAG = 'firmCoverEditPopinDisplayed'
export const POP_IN_FIRM_LOGO_EDIT_DISPLAY_FLAG = 'firmLogoEditPopinDisplayed'
export const PRO_MY_ACCOUNT_NAV2_DISPLAY_FLAG = 'proMyAccountNav2Displayed'
export const DEFAULT_FIRM_LOGO =
  'https://res.cloudinary.com/quotatis/image/upload/FrontApp/icons/default-logo-profil.svg'

export const SINGLE_TYPE_FIRM = 1
export const COMPANY_TYPE_FIRM = 2

export const SIGNUP = 'signup'
export const PRO_DIRECTORY = 'proDirectory'
export const CANDIDATE_PACKAGES = 'candidatePackages'

export const TOKEN = {
  STATUS: {
    ALREADY_USED: 'firm.validate_email.token_already_used',
    EXPIRED: 'firm.validate_email.token_expired',
    ERROR: 'firm.validate_email.error',
    SUCCESS: 'firm.validate_email.success',
  },
}

export const CONTRACT_SIGNATURE_ID = 'contractSignatureId'
export const CONTRACT_SIGNATURE_STATUS = {
  STATUS: { NULL: 'null', FINISHED: 'finished' },
}
export const CONTRACT_SIGNATURE_AT = 'contractSignedAt'
export const LM_ORIGIN = 'LEROY_MERLIN'
export const FIRM_FILE = 'FirmFile'

export const PACKAGE_STATUS_NOT_CANDIDATE = 'pending_test_job'
export const PACKAGE_STATUS_CANDIDATE = 'candidate'
export const PACKAGE_STATUS_PENDING_TEST_JOB = 'pending_test_job'
export const PACKAGE_STATUS_IN_PROGRESS_TEST_JOB = 'in_progress_test_job'
export const PACKAGE_STATUS_VALIDATED = 'validated'
export const PACKAGE_STATUS_SUSPENDED_DEFINITIVELY = 'suspended_definitively'
export const PACKAGE_STATUS_SUSPENDED_TEMPORARY = 'suspended_temporary'

export const FIRM_CONTRACT_OPTIONS = [
  { code: 'PACKAGE', name: 'Package' },
  { code: 'PICK', name: 'Select' },
  { code: 'PUSH', name: 'Connect' },
  { code: 'DIRECTORY', name: 'Directory' },
  { code: 'NO_CONTRACT', name: 'Sans contrat' },
]

export const ADD_USER = 'ADD_USER'
export const DELETE_USER = 'DELETE_USER'
export const UPDATE_NEWSLETTER_OPTINS = 'UPDATE_NEWSLETTER_OPTINS'
export const CERFA = 'CERFA'
export const RGE = 'RGE'

export const DEPARTMENTS_LIST = {
  '01': 'Ain',
  '02': 'Aisne',
  '03': 'Allier',
  '04': 'Alpes de Haute Provence',
  '05': 'Hautes Alpes',
  '06': 'Alpes Maritimes',
  '07': 'Ardèche',
  '08': 'Ardennes',
  '09': 'Ariège',
  10: 'Aube',
  11: 'Aude',
  12: 'Aveyron',
  13: 'Bouches du Rhône',
  14: 'Calvados',
  15: 'Cantal',
  16: 'Charente',
  17: 'Charente Maritime',
  18: 'Cher',
  19: 'Corrèze',
  '2A': 'Corse du Sud',
  '2B': 'Haute Corse',
  21: "Côte d'Or",
  22: "Côtes d'Armor",
  23: 'Creuse',
  24: 'Dordogne',
  25: 'Doubs',
  26: 'Drôme',
  27: 'Eure',
  28: 'Eure et Loir',
  29: 'Finistère',
  30: 'Gard',
  31: 'Haute Garonne',
  32: 'Gers',
  33: 'Gironde',
  34: 'Hérault',
  35: 'Ille et Vilaine',
  36: 'Indre',
  37: 'Indre et Loire',
  38: 'Isère',
  39: 'Jura',
  40: 'Landes',
  41: 'Loir et Cher',
  42: 'Loire',
  43: 'Haute Loire',
  44: 'Loire Atlantique',
  45: 'Loiret',
  46: 'Lot',
  47: 'Lot et Garonne',
  48: 'Lozère',
  49: 'Maine et Loire',
  50: 'Manche',
  51: 'Marne',
  52: 'Haute Marne',
  53: 'Mayenne',
  54: 'Meurthe et Moselle',
  55: 'Meuse',
  56: 'Morbihan',
  57: 'Moselle',
  58: 'Nièvre',
  59: 'Nord',
  60: 'Oise',
  61: 'Orne',
  62: 'Pas de Calais',
  63: 'Puy de Dôme',
  64: 'Pyrénées Atlantiques',
  65: 'Hautes Pyrénées',
  66: 'Pyrénées Orientales',
  67: 'Bas Rhin',
  68: 'Haut Rhin',
  69: 'Rhône-Alpes',
  70: 'Haute Saône',
  71: 'Saône et Loire',
  72: 'Sarthe',
  73: 'Savoie',
  74: 'Haute Savoie',
  75: 'Paris',
  76: 'Seine Maritime',
  77: 'Seine et Marne',
  78: 'Yvelines',
  79: 'Deux Sèvres',
  80: 'Somme',
  81: 'Tarn',
  82: 'Tarn et Garonne',
  83: 'Var',
  84: 'Vaucluse',
  85: 'Vendée',
  86: 'Vienne',
  87: 'Haute Vienne',
  88: 'Vosges',
  89: 'Yonne',
  90: 'Territoire de Belfort',
  91: 'Essonne',
  92: 'Hauts de Seine',
  93: 'Seine St Denis',
  94: 'Val de Marne',
  95: "Val d'Oise",
  97: 'DOM',
  971: 'Guadeloupe',
  972: 'Martinique',
  973: 'Guyane',
  974: 'Réunion',
  975: 'Saint Pierre et Miquelon',
  976: 'Mayotte',
}
