import React from 'react'
import PropTypes from 'prop-types'
import { buildNewUrl, getInfosFromHostname } from 'utils/url'
import { getAllRoutes } from 'routes'
import { UNIVERSES, UNIVERSES_SUB_DOMAIN } from 'constants/routes'
import { Link as RouterLink, matchPath } from 'react-router-dom'
import { Link as UiKitLink } from '@quotatisgroup/uikit-public.ui.link'

const getUniversesHavingPath = (routeDataByUniverse, href) =>
  Object.keys(routeDataByUniverse).filter(universe =>
    routeDataByUniverse[universe].some(
      routeData =>
        matchPath(href, {
          path: routeData.path,
          exact: routeData.exact ?? false,
          strict: routeData.strict ?? false,
        }) !== null,
    ),
  )

const ABSOLUTE_URL_PATTERN = new RegExp('^(?:[a-z+]+:)?//', 'i')

const UniverseLink = ({
  translate,
  lang,
  href,
  universe,
  children,
  rawtranslate,
  dispatch,
  to,
  path,
  ...otherProps
}) => {
  const { isBlock, isBold, iconSize, iconPosition, ...routerLinkProps } =
    otherProps

  if (href === undefined && to === undefined && path === undefined) {
    return <UiKitLink {...otherProps}>{children}</UiKitLink>
  }
  if ((href !== undefined) + (to !== undefined) + (path !== undefined) > 1) {
    throw new Error(
      'Only one prop must be filled between "href" and "to" and "path"',
    )
  }
  const usedHref = href || to || path
  const routeDataByUniverse = getAllRoutes({ translate, lang })
  const {
    subDomain: currentSubDomain,
    env,
    universe: currentUniverse,
  } = getInfosFromHostname()
  const universesHavingPath = getUniversesHavingPath(
    routeDataByUniverse,
    usedHref,
  )

  if (
    usedHref.startsWith('#') ||
    usedHref.startsWith('mailto:') ||
    usedHref.startsWith('tel:')
  ) {
    return (
      <UiKitLink href={usedHref} {...otherProps}>
        {children}
      </UiKitLink>
    )
  }

  if (universe === undefined) {
    if (ABSOLUTE_URL_PATTERN.test(usedHref)) {
      return (
        <UiKitLink href={usedHref} {...otherProps}>
          {children}
        </UiKitLink>
      )
    }

    if (universesHavingPath.length === 0) {
      return (
        <RouterLink to={usedHref} {...routerLinkProps}>
          {children}
        </RouterLink>
      )
    }

    let universeToUse = universesHavingPath[0]

    if (universesHavingPath.length > 1) {
      console.warn(
        `Ambiguous prop, href/to "${usedHref}" is in more than one universe. Set the universe prop in order to remove ambiguity`,
      )
      universeToUse = currentUniverse
    }

    // universesHavingPath.length === 1
    const universeUrl = universeToUse ?? UNIVERSES.PARTNER
    const url = buildNewUrl(env, UNIVERSES_SUB_DOMAIN[universeUrl], usedHref)

    return (
      <UiKitLink href={url} {...otherProps}>
        {children}
      </UiKitLink>
    )
  }

  // universe !== undefined
  if (ABSOLUTE_URL_PATTERN.test(usedHref)) {
    throw new Error(
      `Incorrect usage, href/to "${usedHref}" is an absolute url which is not compatible with universe prop usage`,
    )
  }

  if (UNIVERSES_SUB_DOMAIN[universe] === currentSubDomain) {
    if (
      isBlock !== undefined ||
      isBold !== undefined ||
      iconSize !== undefined ||
      iconPosition !== undefined
    ) {
      return (
        <UiKitLink href={usedHref} {...otherProps}>
          {children}
        </UiKitLink>
      )
    }

    return (
      <RouterLink to={usedHref} {...routerLinkProps}>
        {children}
      </RouterLink>
    )
  }

  // UNIVERSES_SUB_DOMAIN[universe] !== currentSubDomain
  const subdomain =
    UNIVERSES_SUB_DOMAIN[universe] ?? UNIVERSES_SUB_DOMAIN[UNIVERSES.PARTNER]
  const url = buildNewUrl(env, subdomain, usedHref)

  return (
    <UiKitLink href={url} {...otherProps}>
      {children}
    </UiKitLink>
  )
}

UniverseLink.defaultProps = {
  universe: undefined,
  to: undefined,
  path: undefined,
}

UniverseLink.propTypes = {
  translate: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  href: PropTypes.string,
  to: PropTypes.string,
  path: PropTypes.string,
  universe: PropTypes.oneOf(Object.values(UNIVERSES)),
  children: PropTypes.node.isRequired,
}

export default UniverseLink
