/* CONSUMER */
export const CONSUMER_PRO_DESKTOP = 'pro_desktop'

/* FFs */
export const CANDIDATE_PACKAGES_PAGE = 'candidate-packages-page'
export const COOKIE_BANNER = 'cookie-banner'
export const COOKIE_CONSENT = 'cookie-consent'
export const PRO_UNAVAILABILITY_PERIOD = 'pro_unavailability_period'
export const INVOICE_TRANSMISSION = 'invoices_transmission'
export const USE_REFRESH_TOKEN = 'useRefreshToken'
export const DISPLAY_HELP_CENTER_POSTS = 'displayHelpCenterPosts'
